<template>
	<div>


		<div style=" padding:20px;position:relative;" class="btnchange" @click="chooseShop">

			<div style="float:left;width:50px;height:50px;">
				<img :src="'./images/logo.jpg'" style="width:100%; height:100%;border-radius:6px" />
			</div>
			<div style=" margin-left:60px;min-height:50px;">
				<div style="color:#000;font-size:14px">
					当前打印机:{{shopInfo.shop_name}}
				</div>
				<div style="color:gray;font-size:14px; padding-top:10px">
					{{shopInfo.address||shopInfo.shop_name}}
				</div>

			</div>


			<div style="width:20px;height:20px;position:absolute;top:25px;right:10px">
				<img :src="'./images/right.png'" style="width:100%; height:100%;border-radius:4px" />

			</div>



		</div>
		<div style=" padding:10px; padding-top:0; position:relative;">
			<div style="text-align:center; border-top:1px solid #eee;border-bottom:1px solid #eee;  margin-top:10px">
				<div style="color:#000;font-size:14px;line-height:40px">
					<span v-if="printOk>0">当前{{printOk}}台打印机在线</span>
					<span v-else style="color:orangered">当前打印机不在线，无法打印</span>
				</div>
			</div>
		</div>
		<div style="text-align:center">

			<el-upload style="display: inline-block; margin-right: 20px;" :on-progress="showLoading" action="" :http-request="uploadTemp"
				:on-success="uploadFiles" :show-file-list="false" name="image">
				<img :src="'./images/doc.jpg'" style="width:150px; height:150px" />
			</el-upload>




			<div class="">
				打印文档
			</div>
		</div>

		<div style="overflow: hidden;width: 1px;height: 1px;">
			<img :src="'./images/loading.gif'" />
		</div>
		<div class="mask" style="background:rgba(0,0,0,0.5)" v-show="loading">
			<div style=" margin-top:35vh">

			</div>
			<div style=" text-align:center">
				<img :src="'./images/loading.gif'" style="width:100px; height:100px;border-radius:50%" />
				<div class="" style="color:#fff">
					上传中...
				</div>
			</div>

		</div>



	</div>
</template>

<script>
	export default {
		data() {
			return {
				timer: 0,
				sessionid: "",
				files: [],
				shopInfo: {},
				shopid: 0,
				openid: "o3yoj4xDdRiwlFZOYe7EOCGNm_Qc",
				printOk: "",
				loading: false
			}
		},
		mounted() {
			this.openid = Request("openid")
			this.shopid = Request("shopid")
			this.getShop()
		},
		methods: {
			getShop() {
				if (!this.shopid) {
					this.$http.post("/api/get_lastuse_shop", {
						openid: this.openid
					}).then(res => {
						if (res.data.data.length > 0) {
							this.shopid = res.data.data[0].id

							this.getShopInfo()
						}
					})
				} else {
					this.getShopInfo()
				}
			},
			getShopInfo() {
				this.$http.post("/api/h5_shopinfo", {
					shop_id: this.shopid
				}).then(res => {
					if (res.data) {
						if (res.data.time - res.data.last_refresh < 60) {
							if (res.data.printers) {
								let okprint = 0;
								for (let p of res.data.printers) {
									if (p.status > 0) {
										okprint++
									}
								}
								this.printOk = okprint

							}

						}
						this.shopInfo = res.data

					}
				})
			},
			chooseShop() {
				wx.miniProgram.redirectTo({
					url: '/pages/shop/shop'
				})
			},
			showLoading(){
				this.loading = true
			},

			uploadFiles(e) {
				this.loading = false
				
				if(e.src.indexOf(".pdf")<0&&e.src.indexOf(".doc")<0){
					alert("只能上传pdf或者doc文档")
				}else{
					wx.miniProgram.navigateTo({
						url: "/pages/preview/preview?shopid=" + this.shopid + "&name=" + encodeURIComponent(e.fileName) +
							"&url=" + encodeURIComponent(e.src)
					})
				}
				
				


			},
			checkFiles() {
				this.timer = setInterval(e => {
					this.$http.post("/api/check_previewer", {
						sessionid: this.sessionid
					}).then(res => {
						if (res.data && res.data.files) {
							clearInterval(this.timer)
							this.files = res.data.files
						}
					})
				}, 1000)

			}


		}
	}
</script>

<style scoped>
	.mask {
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 10;
		top: 0;
	}
</style>
